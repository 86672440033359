<template>
  <t-page>
    <h3 slot="header">
      {{ $t('pages.organization.delete.heading') }}
    </h3>
    <t-form-card>
      <template #header>
        <h4>
          {{ $t('pages.organization.delete.subheading') }}
        </h4>
      </template>
      <t-button-loading
        :loading="loading"
        type="danger"
        block
        @click.prevent="confirmationVisible = true"
      >
        {{ $t('pages.organization.delete.actions.delete_link') }}
      </t-button-loading>
      <portal to="modals">
        <t-confirm
          v-if="confirmationVisible"
          :button-label="$t('global.actions.confirm_deletion')"
          @confirmed="onConfirmed"
          @cancelled="confirmationVisible = false"
        >
          <template #header>
            {{ $t('pages.organization.delete.heading') }}
          </template>
          <p>
            {{ $t('pages.organization.delete.confirmation.are_you_sure') }}<br />
            {{ $t('pages.organization.delete.confirmation.action_is_final') }}<br />
          </p>
        </t-confirm>
      </portal>
    </t-form-card>
  </t-page>
</template>
<script>
import Vue from 'vue';
import TFormCard from '~/components/TFormCard.vue';
import Organization from '~/models/Organization';
export default Vue.extend({
  components: {
    TFormCard,
  },

  data: () => ({
    loading: false,
    confirmationVisible: false,
  }),

  methods: {
    async onConfirmed() {
      try {
        await new Organization({
          id: this.$route.params.id,
        }).delete();
        await this.$notify.success(this.$t('notifications.organization.deleted'));
        this.deleteConfirmationVisible = false;
        this.$router.push({ name: this.$RouteEnum.ORGANIZATIONS.INDEX });
      } catch (e) {
        this.$axios.handleError(e);
      }
    },
  },
});
</script>
